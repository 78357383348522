import { useMediaQuery } from "@mui/material";
import { observer } from "mobx-react-lite";
import React, { useCallback, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { Experience } from "@busie/api";
import {
  useCustomerAuthToken,
  useDispatchLocationsAuthToken,
  usePagination,
  useQuotesAndBookingsAuthToken,
  useOrganizationSubscription,
  useTripsAuthToken,
} from "@busie/core";
import { notificationStore } from "@busie/features";
import {
  Button,
  NoContentBlock,
  PlusIcon,
  QuotesEmptyState,
  palette,
} from "@busie/ui-kit";
import { QuoteAndBookingStatus } from "@busie/utils";

import { PageHeader, QuotesTable } from "../entity";
import {
  ListQuotesQuery,
  SortQuotesQuery,
  quotesStore,
  useFetchQuote,
  useListQuotes,
} from "../model";
import { ListControls } from "../shared/ui";
import {
  ChefQuoteDetailsDrawer,
  ChefQuotesTable,
  CreateTrip,
  QuoteDetailsDrawer,
  TripDetailsDrawer,
  UpdateQuote,
  UpdateTrip,
} from "../widgets";

interface Props {
  isCustomerView?: boolean;
}

const DEFAULT_REFETCH_INTERVAL = 5 * 1000; // 5 seconds

export const QuotesPage: React.FC<React.PropsWithChildren<Props>> = observer(
  ({ isCustomerView }) => {
    const tripsAuthToken = useTripsAuthToken();
    const quotesAuthToken = useQuotesAndBookingsAuthToken();
    const customersAuthToken = useCustomerAuthToken();
    const dispatchLocationsAuthToken = useDispatchLocationsAuthToken();

    const [currentPage, setPage] = usePagination();
    const [chefPage, setChefPage] = useState(1);

    const [sort, setSort] = useState<SortQuotesQuery>({
      CREATED_AT: "desc",
    });
    const [query, setQuery] = useState<ListQuotesQuery>({
      status: [QuoteAndBookingStatus.PENDING, QuoteAndBookingStatus.CONFIRMED],
      dispatchLocations: [],
      startDate: [null, null],
      createdDate: [null, null],
      assigneeId: undefined,
    });

    const [isCreateTripOpen, setIsCreateTripOpen] = useState(false);
    const [isEditTripOpen, setIsEditTripOpen] = useState(false);
    const [isEditQuoteOpen, setIsEditQuoteOpen] = useState(false);
    const [isTripDetailsOpen, setIsTripDetailsOpen] = useState(false);
    const [quotesRefetchInterval, setQuotesRefetchInterval] = useState(
      DEFAULT_REFETCH_INTERVAL
    );
    const [isQuoteDetailsOpen, setIsQuoteDetailsOpen] = useState(false);

    const { search } = useLocation();
    const searchParams = new URLSearchParams(search);
    const [quoteId, setQuoteId] = useState(searchParams.get("quoteId"));

    const {
      data: quote,
      isError: isFetchQuoteError,
      isLoading: isLoadingQuote,
    } = useFetchQuote(quoteId as string, quotesRefetchInterval);

    const { isPremium: rolloutChef } = useOrganizationSubscription(
      quote?.ORGANIZATION_ID
    );

    const {
      data: quotesWithTotal,
      isError: isListQuotesError,
      isFetching: isFetchingQuotes,
      isLoading: isLoadingQuotes,
    } = useListQuotes(chefPage, sort, query);

    const [trip, setTrip] = useState<Experience>();

    const isMobile = useMediaQuery("@media (max-width: 960px)");

    useEffect(() => {
      quotesStore.setAuthTokens({
        trips: tripsAuthToken,
        quotes: quotesAuthToken,
        customers: customersAuthToken,
        dispatchLocations: dispatchLocationsAuthToken,
      });
      if (
        quotesStore.items.length === 0 &&
        tripsAuthToken &&
        quotesAuthToken &&
        dispatchLocationsAuthToken &&
        customersAuthToken
      ) {
        quotesStore.setItems(currentPage);
      }
    }, [
      tripsAuthToken,
      quotesAuthToken,
      customersAuthToken,
      dispatchLocationsAuthToken,
      currentPage,
    ]);

    const history = useHistory();

    const onAddQuote = () => {
      history.push("/quotes-and-bookings/add-quote");
    };

    useEffect(() => {
      return () => {
        quotesStore.reset();
      };
    }, []);

    useEffect(() => {
      if (isListQuotesError && rolloutChef)
        notificationStore.setErrorNotification(
          "Error",
          "Could not retrieve quotes list"
        );
    }, [isListQuotesError, quotesWithTotal, rolloutChef]);

    useEffect(() => {
      if (isFetchQuoteError && rolloutChef) {
        notificationStore.setErrorNotification(
          "Not Found",
          "Could not find a quote with the specified id"
        );
      }
    }, [isFetchQuoteError, quote, rolloutChef]);

    useEffect(() => {
      if (quotesRefetchInterval !== DEFAULT_REFETCH_INTERVAL) {
        setTimeout(() => {
          setQuotesRefetchInterval(DEFAULT_REFETCH_INTERVAL);
        }, quotesRefetchInterval * 6);
      }
    }, [quotesRefetchInterval]);

    useEffect(() => {
      setIsQuoteDetailsOpen(!!quoteId);
    }, [quoteId]);

    const [isNoContent, setIsNoContent] = useState(
      !quotesStore.isFetching && quotesStore.isEmpty
    );

    useEffect(() => {
      if (rolloutChef) {
        setIsNoContent(
          !quotesWithTotal &&
            !isListQuotesError &&
            !isFetchingQuotes &&
            !isLoadingQuotes
        );
      }
    }, [
      quotesWithTotal,
      isListQuotesError,
      isFetchingQuotes,
      isLoadingQuotes,
      rolloutChef,
    ]);

    if (isNoContent) {
      return (
        <NoContentBlock
          icon={<QuotesEmptyState />}
          text="You don't have any quotes yet. Add your first quote."
          controls={
            <Button
              onClick={onAddQuote}
              startIcon={<PlusIcon color={palette?.black?.plus100} />}
            >
              Add quote
            </Button>
          }
        />
      );
    }

    return (
      <>
        {!isCustomerView && !isMobile && !rolloutChef && <PageHeader />}

        <ListControls store={quotesStore} />

        {(!rolloutChef && (
          <>
            <QuotesTable
              dispatchLocations={quotesStore.dispatchLocations}
              quotes={quotesStore.items}
              quotesResponse={quotesStore.quotesResponse || null}
              currentPage={currentPage}
              setPage={setPage}
            />
            <QuoteDetailsDrawer
              onListUpdate={() => quotesStore.setItems(currentPage)}
              authTokens={{
                trips: tripsAuthToken,
                quotes: quotesAuthToken,
                customers: customersAuthToken,
              }}
              isCustomerView={isCustomerView}
            />
          </>
        )) || (
          <>
            <ChefQuotesTable
              quotes={quotesWithTotal}
              currentPage={chefPage}
              isLoading={isLoadingQuotes}
              defaultQuery={{
                status: query.status,
                dispatchLocations: query.dispatchLocations,
                startDate: query.startDate,
                createdDate: query.createdDate,
                search: query.search,
                assigneeId: query.assigneeId,
              }}
              onRowClick={(id) => setQuoteId(id)}
              onPageChange={(newPage) => setChefPage(newPage)}
              onQueryChange={(query) =>
                setQuery((curr) => ({ ...curr, ...query }))
              }
              onSortChange={useCallback((sort) => setSort(sort), [])}
              onAddNewQuote={onAddQuote}
              onSearchChange={(search) =>
                setQuery((curr) => ({ ...curr, search }))
              }
            />

            {quoteId ? (
              <ChefQuoteDetailsDrawer
                quote={quote}
                isOpen={isQuoteDetailsOpen}
                isLoading={isLoadingQuote}
                onEditQuote={() => setIsEditQuoteOpen(true)}
                onAddTrip={() => setIsCreateTripOpen(true)}
                onClose={() => {
                  setIsQuoteDetailsOpen(false);
                  setQuoteId(null);
                }}
                onTripClicked={(trip) => {
                  setTrip(trip);
                  setIsTripDetailsOpen(true);
                }}
                onQuoteUpdated={() => setQuotesRefetchInterval(2000)}
              />
            ) : null}

            {quoteId && quote ? (
              <CreateTrip
                isOpen={isCreateTripOpen}
                quoteId={quoteId}
                organizationId={quote.ORGANIZATION_ID}
                onClose={() => setIsCreateTripOpen(false)}
                customerGroupId={quote.CUSTOMER_GROUP_ID}
                mainContactId={
                  quote.MAIN_CONTACT_ID || quote.EXPERIENCES[0].MAIN_CONTACT?.ID
                }
                onTripCreated={() => {
                  setIsCreateTripOpen(false);
                  setQuotesRefetchInterval(2000);
                }}
              />
            ) : null}

            {trip && (
              <TripDetailsDrawer
                isOpen={isTripDetailsOpen}
                trip={trip}
                onClose={() => setIsTripDetailsOpen(false)}
                onEditTrip={() => setIsEditTripOpen(true)}
              />
            )}

            {trip && (
              <UpdateTrip
                isOpen={isEditTripOpen}
                onClose={() => setIsEditTripOpen(false)}
                trip={trip}
                onSuccess={() => {
                  setIsEditTripOpen(false);
                  setQuotesRefetchInterval(2000);
                }}
              />
            )}

            {quote && (
              <UpdateQuote
                isOpen={isEditQuoteOpen}
                quote={quote}
                onClose={() => setIsEditQuoteOpen(false)}
                onSuccess={() => {
                  setQuotesRefetchInterval(2000);
                  setIsEditQuoteOpen(false);
                }}
                onAddTrip={() => setIsCreateTripOpen(true)}
              />
            )}
          </>
        )}
      </>
    );
  }
);
